import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Card, Col, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function TestManagement() {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const data = [
    { name: "STCW.95", type: "Un Paid", category: "3", to: "/unpaid-category" },
    { name: "OOW 3000", type: "Paid", category: "7", to: "/paid-category" },
  ];

  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  // async function tableListData() {
  //   setLoader(true);
  //   const res = await testsListApi();
  //   setTableData(res?.data?.data);
  //   setLoader(false);
  // }

  // useEffect(() => {
  //   tableListData();
  // }, []);

  return (
    <div>
      {" "}
      <div>
        <PageTitle
          activeMenu="Modules List"
          motherMenu="Modules Management"
          isButton={false}
          //   action={setShowAddTestModal}
        />

        <Col>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black", textAlign: "center" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Test type</strong>
                    </th>

                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data.map((item, i) => (
                    <tr key={item.id}>
                      <td>{item?.name}</td>
                      <td>{item?.type}</td>

                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate(item?.to)}>
                              Subjects
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
}
