import axiosInstance from "../../../services/AxiosInstance";
import qs from "qs";

// const deshBoardApiURL = "https://api.baraatco.com/api/v1/admin/home";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("admin/userManage/dashboard", {});
  return response;
}

export async function userManagementList(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get("admin/userManage/getUsers", {});
  return response;
}

export async function getQuestionsApi(postData) {
  const queryString = qs.stringify(postData);

  const response = await axiosInstance.get(`admin/question?${queryString}`);
  return response;
}

export async function testsListApi() {
  const response = await axiosInstance.get("admin/test", {});
  return response;
}
export async function getSubjectApi(testName, currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `admin/test/category?testName=${testName}&page=${currentPage}&limit=${itemsPerPage}`,
    {}
  );
  return response;
}
export async function addSubjectApi(postData) {
  const response = await axiosInstance.post(`admin/test/category`, postData);
  return response;
}
export async function editSubjectApi(postData) {
  const response = await axiosInstance.put(`admin/test/category`, postData);
  return response;
}
export async function getCategoryApi(postData) {
  const response = await axiosInstance.get(
    `admin/test/subCategory?testName=${postData?.testName}&categoryId=${postData?.categoryId}`
  );
  return response;
}
export async function addCategoryApi(postData) {
  const response = await axiosInstance.post(`admin/test/subCategory`, postData);
  return response;
}
export async function editCategoryApi(postData) {
  const response = await axiosInstance.put(`admin/test/subCategory`, postData);
  return response;
}
export async function getReportsListApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `admin/question/reports?page=${currentPage}&limit=${itemsPerPage}`,
    {}
  );
  return response;
}
export async function statusReportsApi(id) {
  const data = { reportId: id };
  const response = await axiosInstance.put(`admin/question/reports`, data);
  return response;
}

export async function createTestApi(testName, testType) {
  const data = { testName, testType };
  const response = await axiosInstance.post("admin/test", data);
  return response;
}
export async function createQuestionApi(data) {
  const response = await axiosInstance.post("admin/question", data);
  return response;
}
export async function editQuestionApi(data) {
  const response = await axiosInstance.put("admin/question", data);
  return response;
}

export async function blockUserApi(id) {
  const data = { userId: id };
  const response = await axiosInstance.put("admin/userManage/userBlock", data);
  return response;
}

export async function deleteUserApi(id) {
  const response = await axiosInstance.delete(
    `admin/userManage/deleteUser?userId=${id}`
  );
  return response;
}

export async function deleteSubjectApi(id) {
  const response = await axiosInstance.delete(`admin/test/category?id=${id}`);
  return response;
}
export async function deleteCategoryApi(id) {
  const response = await axiosInstance.delete(
    `admin/test/subCategory?id=${id}`
  );
  return response;
}
export async function deleteQuestionsApi(id) {
  const response = await axiosInstance.delete(
    `admin/question?questionId=${id}`
  );
  return response;
}
