import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Dropdown, Table } from "react-bootstrap";
import {
  addCategoryApi,
  deleteCategoryApi,
  editCategoryApi,
  getCategoryApi,
} from "../components/APIs/Api";
import moment from "moment";
import toast from "react-hot-toast";
import AddNewModal from "../modal/AddNewModal";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import Pagination from "../components/Common/Pagination";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
export default function SubCategory() {
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isAddCategory, setAddCategory] = useState(false);
  const [isEditCategory, setEditCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    testName: location?.state?.testName,
    categoryId: location?.state?._id,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  async function fetchCategoriesData() {
    setLoader(true);
    const postData = {
      testName: location?.state?.testName,
      categoryId: location?.state?._id,
    };
    try {
      const res = await getCategoryApi(postData);
      setCategories(res?.data?.data);
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  //=========pagination=============
  const totalItems = categories?.subCategoryCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function handleDelete(id) {
    try {
      const res = await deleteCategoryApi(id);
      toast.success("Category deleted successfully");
      fetchCategoriesData();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    }
  }
  useEffect(() => {
    fetchCategoriesData();
  }, [currentPage]);

  return (
    <div>
      <div>
        <PageTitle
          activeMenu={"Categories List"}
          motherMenu={location?.state?.testName}
          isButton={true}
          action={setAddCategory}
        />
      </div>
      {loader ? (
        <div className="">
          <Lottie
            style={{ height: "200px", marginTop: "45px", padding: "30px" }}
            animationData={animationData}
          />
        </div>
      ) : (
        <Card>
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Subject Name</strong>
                  </th>
                  <th>
                    <strong>Category Name</strong>
                  </th>
                  <th>
                    <strong>created at</strong>
                  </th>

                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories?.subCategoryListing?.map((item, i) => (
                  <tr key={item.id}>
                    <td>
                      {item?.categoryId?.categoryName?.length > 20
                        ? item?.categoryId?.categoryName.slice(0, 20) + "..."
                        : item?.categoryId?.categoryName}
                    </td>
                    <td>
                      {item?.subCategoryName?.length > 20
                        ? item?.subCategoryName.slice(0, 20) + "..."
                        : item?.subCategoryName}
                    </td>
                    <td>{moment(item.createdAt).format("ll")}</td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              navigate("/paid-questions", { state: item })
                            }
                          >
                            Questions
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedCategory(item);
                              setEditCategory(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* )} */}
            {categories?.subCategoryCount === 0 && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {categories?.subCategoryCount !== 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Categories <span>{totalItems}</span>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <Pagination
                    pageCount={totalPages}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {isAddCategory && (
        <AddNewModal
          show={isAddCategory}
          onHide={() => setAddCategory(false)}
          title={"Add Category"}
          api={addCategoryApi}
          refresh={fetchCategoriesData}
          data={data}
        />
      )}
      {isEditCategory && (
        <AddNewModal
          show={isEditCategory}
          onHide={() => setEditCategory(false)}
          title={"Update"}
          api={editCategoryApi}
          refresh={fetchCategoriesData}
          data={selectedCategory}
        />
      )}
    </div>
  );
}
