import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  userManagementList,
  blockUserApi,
  deleteUserApi,
} from "..//components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "..//../images/loding.json";
import { variable, s3BaseUrl } from "../components/Variable";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import Filter from "../components/Filter/Filter";
import { limitOptions } from "../components/Filter/FilterLable";
import Pagination from "../components/Common/Pagination";
import S3Image from "../components/S3Image";

export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [changeUserPassword, setChangeUserPassword] = useState(false);
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  //============usersList============
  async function userListData() {
    setLoader(true);
    const res = await userManagementList(currentPage, itemsPerPage, search);
    setUsersList(res);
    setLoader(false);
  }
  console.log(usersList);

  //=========pagination=============
  const totalItems = usersList?.data?.data?.countUser;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //+++++++=blockedUsers==================
  async function blockUser(id) {
    const res = await blockUserApi(id);
    setLoader(false);
    userListData();
    toast.success(res?.data?.data?.updateUser);
  }
  // ///===========delete user======================
  async function hendleDeleteUser(id) {
    try {
      const res = await deleteUserApi(id);
      userListData();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }

  function deleteUserPromise(id) {
    toast.promise(hendleDeleteUser(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  useEffect(() => {
    userListData();
    scrollToTop();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div>
        <PageTitle activeMenu="Users List" motherMenu="User Management" />
        <div className="pb-4">
          <Filter searchType="search" setSearch={setSearch} />
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                {/* {loader ? (
                  <UserListingShimmerLoader className='table' />
                ) : ( */}
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>

                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE NUMBER</strong>
                      </th>
                      <th>
                        <strong>department</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.Users?.map((item, i) => (
                      <tr key={item.id}>
                        <td>
                          <S3Image
                            imageKey={item?.profileImage}
                            // imageStyle={"profile-img"}
                          />{" "}
                        </td>

                        <td>
                          {item?.name?.length > 15
                            ? item?.name.slice(0, 15) + "..."
                            : item?.name}
                        </td>
                        <td>
                          {item?.email?.length > 20
                            ? item?.email.slice(0, 20) + "..."
                            : item?.email}
                        </td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.department}</td>

                        <td>
                          {item.isBlocked === false ? (
                            <span class="badge-success light badge">
                              Active
                            </span>
                          ) : (
                            <span class="badge-danger light badge">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* <Dropdown.Item
                                onClick={() => {
                                  variable.id = item.id;
                                  props.history.push("/user-details");
                                }}
                              >
                                View Details
                              </Dropdown.Item> */}
                              {item.isBlocked === false ? (
                                <Dropdown.Item
                                  onClick={() => blockUser(item._id)}
                                >
                                  Inactive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => blockUser(item._id, 1)}
                                >
                                  Active
                                </Dropdown.Item>
                              )}

                              <Dropdown.Item
                                onClick={() => deleteUserPromise(item._id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.countUser === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.countUser !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total Users <span>{totalItems}</span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Filter
                        setFilterType={setItemPerPage}
                        FilterOption={limitOptions}
                        filterType="filterType"
                        limitValue={itemsPerPage}
                      />
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
